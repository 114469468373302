<template>
  <div>
    <kandidat-menu
      :kandidat="kandidat"
      :active-item="activeItem"
    />

    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="$t('Zavrsi')"
      :back-button-text="$t('Prethodno')"
      :next-button-text="$t('Sledece')"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <tab-content
        :title="$t('PodaciOPlacanju')"
      >
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('NacinPlacanja')"
              label-for="nacin_placanja"
            >
              <v-select
                v-model="nacin_placanja"
                label="text"
                :options="naciniPlacanja"
                :placeholder="$t('IzaberiteNacin')"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <!-- basic -->
            <b-form-group
              :label="$t('RokRealizacije')"
              label-for="rok_realizacije"
            >
              <b-form-datepicker
                id="rok_realizacije"
                v-model="rok_realizacije"
                :placeholder="$t('RokRealizacije')"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                :locale="this.$i18n.locale"
                :hide-header="hideHeader"
                today-button
                reset-button
                close-button
                show-decade-nav
                :label-today-button="$t('Danas')"
                :label-reset-button="$t('Ponisti')"
                :label-close-button="$t('Zatvori')"
                :label-prev-year="$t('PrethodnaGodina')"
                :label-prev-month="$t('PrethodniMesec')"
                :label-next-year="$t('SledecaGodina')"
                :label-next-month="$t('SledeciMesec')"
                :label-current-month="$t('TekuciMesec')"
                :label-prev-decade="$t('MinusDesetGodina')"
                :label-next-decade="$t('PlusDesetGodina')"
                label-help=""
                no-flip
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('Maloletan')"
              label-for="maloletan"
            >
              <v-select
                v-model="maloletan"
                autocomplete="off"
                label="text"
                :options="maloletanOpcije"
                :placeholder="$t('Maloletan')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="maloletan.value == 1"
        >
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('Roditelj/Staratelj')"
              label-for="roditelj"
            >
              <v-select
                v-model="roditelj"
                autocomplete="off"
                label="text"
                :options="roditeljOpcije"
                :placeholder="$t('Roditelj/Staratelj')"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('ImeRoditelja/Staratelja')"
              label-for="ime_roditelja"
            >
              <b-form-input
                v-model="ime_roditelja"
                :placeholder="$t('ImeRoditelja/Staratelja')"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('BrojLicneKarteRoditeljaStaratelja')"
              label-for="broj_licne_karte_roditelja_staratelja"
            >
              <b-form-input
                v-model="brojLicneKarteRoditelja"
                :placeholder="$t('BrojLicneKarteRoditeljaStaratelja')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('ObrazacUgovora')"
              label-for="obrazac"
            >
              <v-select
                v-model="obrazac_ugovora_id"
                autocomplete="off"
                label="naziv"
                :options="obrasciUgovora"
                :placeholder="$t('ObrazacUgovora')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="12"
            xl="12"
          >
            <b-form-group
              :label="$t('Napomena')"
              label-for="napomena"
            >
              <b-form-textarea
                id="napomena"
                v-model="napomena"
                :placeholder="$t('Napomena')"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
      <tab-content
        :title="$t('Cene')"
      >
        <b-form @submit.prevent>
          <b-form-row>

            <!-- valid input  -->
            <b-col
              md="8"
              class="mb-6"
            >
              <b-form-group
                :label="$t('SablonCena')"
                label-for="sablon"
              >
                <v-select
                  v-model="sablon"
                  label="text"
                  :options="sabloni"
                  :placeholder="$t('IzaberiteSablon')"
                />
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col
              cols="12"
            >
              <div class="demo-inline-spacing">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  @click="pokupiCene"
                >
                  {{ $t('PreuzmiCene') }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  @click="ponistiCene"
                >
                  {{ $t('Poništi') }}
                </b-button>
              </div>
            </b-col>
          </b-form-row>
        </b-form>
        <b-card no-body>
          <b-card-body>
            <div>
              <b-table-simple
                hover
                small
                caption-top
                responsive
              >
                <colgroup><col><col></colgroup>
                <colgroup><col><col><col></colgroup>
                <colgroup><col><col></colgroup>
                <b-thead head-variant="light">
                  <b-tr>
                    <b-th>
                      {{ $t('RedniBroj') }}
                    </b-th>
                    <b-th>
                      {{ $t('Opis') }}
                    </b-th>
                    <b-th>
                      {{ $t('JedinicaMere') }}
                    </b-th>
                    <b-th>
                      {{ $t('CenaJedinice') }}
                    </b-th>
                    <b-th>
                      {{ $t('Kolicina') }}
                    </b-th>
                    <b-th>
                      {{ $t('Ukupno') }}
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>1.</b-td>
                    <b-td>
                      <span style="font-weight:bold;">
                        {{ $t('TeorijskaObuka') }}
                      </span>
                    </b-td>
                    <b-td>
                      {{ $t('n/cas') }}
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="teorijska_obuka_cena"
                        size="sm"
                        :placeholder="$t('CenaJedinice')"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="teorijska_obuka_kolicina"
                        size="sm"
                        :placeholder="$t('Kolicina')"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="ukupnoTeorijskaObuka"
                        size="sm"
                        type="number"
                        :placeholder="$t('Ukupno')"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>2.</b-td>
                    <b-td>
                      {{ $t('DodatnaTeorijskaObuka') }}
                    </b-td>
                    <b-td>
                      {{ $t('n/cas') }}
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="dodatna_teorijska_obuka_cena"
                        size="sm"
                        :placeholder="$t('CenaJedinice')"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="dodatna_teorijska_obuka_kolicina"
                        size="sm"
                        :placeholder="$t('Kolicina')"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="ukupno_dodatna_teorijska_obuka"
                        size="sm"
                        type="number"
                        :placeholder="$t('Ukupno')"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>3.</b-td>
                    <b-td>
                      {{ $t('DopunskaTeorijskaObuka') }}
                    </b-td>
                    <b-td>
                      {{ $t('n/cas') }}
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="dopunska_teorijska_obuka_cena"
                        size="sm"
                        :placeholder="$t('CenaJedinice')"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="dopunska_teorijska_obuka_kolicina"
                        size="sm"
                        :placeholder="$t('Kolicina')"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="ukupno_dopunska_teorijska_obuka"
                        size="sm"
                        type="number"
                        :placeholder="$t('Ukupno')"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>4.</b-td>
                    <b-td>
                      {{ $t('PolaganjeTeorijskogIspita') }}
                    </b-td>
                    <b-td>
                      {{ $t('kom') }}
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="polaganje_teorijskog_ispita_cena"
                        size="sm"
                        :placeholder="$t('CenaJedinice')"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="polaganje_teorijskog_ispita_kolicina"
                        size="sm"
                        :placeholder="$t('Kolicina')"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="ukupno_polaganje_teorijskog_ispita"
                        size="sm"
                        type="number"
                        :placeholder="$t('Ukupno')"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>5.</b-td>
                    <b-td>
                      <span style="font-weight:bold;">
                        {{ $t('PrakticnaObuka') }}
                      </span>
                    </b-td>
                    <b-td>
                      {{ $t('n/cas') }}
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="prakticna_obuka_cena"
                        size="sm"
                        :placeholder="$t('CenaJedinice')"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="prakticna_obuka_kolicina"
                        size="sm"
                        :placeholder="$t('Kolicina')"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="ukupno_prakticna_obuka"
                        size="sm"
                        type="number"
                        :placeholder="$t('Ukupno')"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>6.</b-td>
                    <b-td>
                      {{ $t('DodatnaPrakticnaObuka') }}
                    </b-td>
                    <b-td>
                      {{ $t('n/cas') }}
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="dodatna_prakticna_obuka_cena"
                        size="sm"
                        :placeholder="$t('CenaJedinice')"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="dodatna_prakticna_obuka_kolicina"
                        size="sm"
                        :placeholder="$t('Kolicina')"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="ukupno_dodatna_prakticna_obuka"
                        size="sm"
                        type="number"
                        :placeholder="$t('Ukupno')"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>7.</b-td>
                    <b-td>
                      {{ $t('DopunskaPrakticnaObuka') }}
                    </b-td>
                    <b-td>
                      {{ $t('n/cas') }}
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="dopunska_prakticna_obuka_cena"
                        size="sm"
                        :placeholder="$t('CenaJedinice')"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="dopunska_prakticna_obuka_kolicina"
                        size="sm"
                        :placeholder="$t('Kolicina')"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="ukupno_dopunska_prakticna_obuka"
                        size="sm"
                        type="number"
                        :placeholder="$t('Ukupno')"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>8.</b-td>
                    <b-td>
                      {{ $t('PolaganjePrakticnogIspita') }}
                    </b-td>
                    <b-td>
                      {{ $t('kom') }}
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="polaganje_prakticnog_ispita_cena"
                        size="sm"
                        :placeholder="$t('CenaJedinice')"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="polaganje_prakticnog_ispita_kolicina"
                        size="sm"
                        :placeholder="$t('Kolicina')"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="ukupno_polaganje_prakticnog_ispita"
                        size="sm"
                        type="number"
                        :placeholder="$t('Ukupno')"
                      />
                    </b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr>
                    <b-td
                      colspan="6"
                      variant="secondary"
                      class="text-right"
                    >
                      {{ $t('Svega') }}: <b>{{ iznos }}</b>
                    </b-td>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
            </div>
          </b-card-body>
        </b-card>
      </tab-content>
      <tab-content
        v-if="nacin_placanja.value === 'rate'"
        :title="$t('Rate')"
      >
        <b-form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
          @submit.prevent="dodajRatu"
        >
          <!-- Row Loop -->
          <b-row
            v-for="(rata, index) in rate"
            :id="rata.id"
            :key="rata.id"
            ref="row"
          >
            <b-col md="4">
              <b-form-group
                :label="$t('DatumRate')"
              >
                <b-form-datepicker
                  v-model="rata.datum"
                  :placeholder="$t('Datum')"
                  class="mb-1"
                  no-flip
                  :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                  :locale="currentLocale"
                  :hide-header="hideHeader"
                  today-button
                  reset-button
                  close-button
                  show-decade-nav
                  :label-today-button="$t('Danas')"
                  :label-reset-button="$t('Ponisti')"
                  :label-close-button="$t('Zatvori')"
                  :label-prev-year="$t('PrethodnaGodina')"
                  :label-prev-month="$t('PrethodniMesec')"
                  :label-next-year="$t('SledecaGodina')"
                  :label-next-month="$t('SledeciMesec')"
                  :label-current-month="$t('TekuciMesec')"
                  :label-prev-decade="$t('MinusDesetGodina')"
                  :label-next-decade="$t('PlusDesetGodina')"
                  label-help=""
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                :label="$t('Iznos')"
              >
                <b-form-input
                  v-model="rata.iznos"
                  name="iznosRate"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              lg="2"
              md="3"
              class="mb-50"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>{{ $t('Obrisi') }}</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
        </b-form>
        <b-row>
          <b-col md="3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :disabled="nacin_placanja.value !== 'rate'"
              @click="dodajRatu"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>{{ $t('DodajRatu') }}</span>
            </b-button>
          </b-col>
          <b-col
            v-if="rate.length === 1 && nacin_placanja.value === 'rate'"
            md="2"
          >
            {{ $t('IliPodeliNa') }}
          </b-col>
          <b-col
            v-if="rate.length === 1 && nacin_placanja.value === 'rate'"
            md="6"
          >
            <b-input-group>
              <b-form-input
                v-model="broj_rata_za_podelu"
                :placeholder="$t('BrojRata')"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="podeliNaRate"
                >
                  {{ $t('Podeli') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </tab-content>
      <tab-content
        :title="$t('Pregled')"
      >
        <section id="card-style-variation">
          <b-row>
            <!-- solid color -->
            <b-col
              md="6"
              xl="4"
            >
              <b-card
                bg-variant="transparent"
                border-variant="secondary"
              >
                <b-card-title>
                  {{ $t('PodaciOKandidatu') }}
                </b-card-title>
                <b-card-text>
                  <b-list-group>
                    <b-list-group-item
                      class="flex-column align-items-start"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                          {{ $t('Kandidat') }}
                        </h5>
                      </div>
                      <b-card-text class="mb-1">
                        {{ kandidat.ime }} {{ kandidat.prezime }}
                      </b-card-text>
                    </b-list-group-item>
                    <b-list-group-item
                      class="flex-column align-items-start"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                          {{ $t('DatumUpisaURegistar') }}
                        </h5>
                      </div>
                      <b-card-text class="mb-1">
                        {{ getHumanDate(datum_upisa_u_registar) }}
                      </b-card-text>
                    </b-list-group-item>
                    <b-list-group-item
                      class="flex-column align-items-start"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                          {{ $t('Maloletan') }}
                        </h5>
                      </div>
                      <b-card-text class="mb-1">
                        {{ maloletan.text }}
                      </b-card-text>
                      <b-card-text
                        v-if="maloletan.value === 1"
                        class="mb-1"
                      >
                        {{ $t(roditelj.text) }} - {{ ime_roditelja }}, {{ $t('BrojLicneKarte') }} :  {{ brojLicneKarteRoditelja }}
                      </b-card-text>
                    </b-list-group-item>
                    <b-list-group-item
                      class="flex-column align-items-start"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                          {{ $t('Napomena') }}
                        </h5>
                      </div>
                      <b-card-text class="mb-1">
                        {{ napomena }}
                      </b-card-text>
                    </b-list-group-item>
                  </b-list-group>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col
              md="6"
              xl="4"
            >
              <b-card
                bg-variant="transparent"
                border-variant="secondary"
              >
                <b-card-title>
                  {{ $t('PodaciOPlacanju') }}
                </b-card-title>
                <b-card-text>
                  <b-list-group>
                    <b-list-group-item
                      class="flex-column align-items-start"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                          {{ $t('NacinPlacanja') }}
                        </h5>
                      </div>
                      <b-card-text class="mb-1">
                        {{ nacin_placanja.text }}
                      </b-card-text>
                    </b-list-group-item>
                    <b-list-group-item
                      class="flex-column align-items-start"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                          {{ $t('RokRealizacije') }}
                        </h5>
                      </div>
                      <b-card-text class="mb-1">
                        {{ getHumanDate(rok_realizacije) }}
                      </b-card-text>
                    </b-list-group-item>
                    <b-list-group-item
                      class="flex-column align-items-start"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                          {{ $t('UkupanIznos') }}
                        </h5>
                      </div>
                      <b-card-text class="mb-1">
                        {{ formatIznos(iznos) }}
                      </b-card-text>
                    </b-list-group-item>
                    <b-list-group-item
                      class="flex-column align-items-start"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                          {{ $t('BrojRata') }}
                        </h5>
                      </div>
                      <b-card-text class="mb-1">
                        {{ rate.length }}
                      </b-card-text>
                    </b-list-group-item>
                  </b-list-group>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </tab-content>
    </form-wizard>
    <b-modal
      id="modal-danger"
      ok-only
      ok-variant="danger"
      :ok-title="$t('Obrisi')"
      modal-class="modal-danger"
      centered
      :title="$t('Upozorenje')"
    >
      <b-card-text>
        {{ $t('IznosRataIIznosUgovoraSeNeSlazu') }}
      </b-card-text>
      <b-card-text>
        {{ $t('ZbirIznosaRataJe:') }} {{ formatIznos(rate.reduce((a, b) => +a + +b.iznos, 0)) }}
      </b-card-text>
      <b-card-text>
        {{ $t('IznosUgovoraJe:') }} {{ formatIznos(iznos) }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import moment from 'moment'
import {
  BButton,
  BCardText,
  BCardTitle,
  BForm,
  BFormRow,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BCol,
  BFormGroup,
  BFormDatepicker,
  BFormTextarea,
  BCard,
  BCardBody,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BFormInput,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import KandidatMenu from '../kandidati/KandidatMenu.vue'

export default {
  components: {
    'kandidat-menu': KandidatMenu,
    BButton,
    BCardText,
    BCardTitle,
    BForm,
    BFormRow,
    BInputGroup,
    BInputGroupAppend,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormDatepicker,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormTextarea,
    BCard,
    BCardBody,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BFormInput,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      activeItem: 'ugovori',
      obrazac_ugovora_id: 0,
      kandidat: {},
      sablon: {},
      sabloni: [],
      datum_upisa_u_registar: '',
      rate: [],
      broj_rata_za_podelu: 0,
      nextTodoId: 2,
      hideHeader: true,
      rok_realizacije: '',
      maloletan: { value: '0', text: this.$i18n.t('Punoletan') },
      roditelj: { value: '1', text: this.$i18n.t('Roditelj') },
      nacin_placanja: '',
      napomena: '',
      naciniPlacanja: [
        { value: 'unapred', text: this.$i18n.t('Unapred') },
        { value: 'rate', text: this.$i18n.t('NaRate') },
        { value: 'do_kraja', text: this.$i18n.t('DoKraja') },
      ],
      maloletanOpcije: [
        { value: '1', text: this.$i18n.t('Maloletan') },
        { value: '0', text: this.$i18n.t('Punoletan') },
      ],
      roditeljOpcije: [
        { value: '1', text: this.$i18n.t('Roditelj') },
        { value: '0', text: this.$i18n.t('Staratelj') },
      ],
      ime_roditelja: '',
      brojLicneKarteRoditelja: '',
      teorijska_obuka_cena: 0,
      teorijska_obuka_kolicina: 0,
      dodatna_teorijska_obuka_cena: 0,
      dodatna_teorijska_obuka_kolicina: 0,
      dopunska_teorijska_obuka_cena: 0,
      dopunska_teorijska_obuka_kolicina: 0,
      polaganje_teorijskog_ispita_cena: 0,
      polaganje_teorijskog_ispita_kolicina: 0,

      prakticna_obuka_cena: 0,
      prakticna_obuka_kolicina: 0,
      dodatna_prakticna_obuka_cena: 0,
      dodatna_prakticna_obuka_kolicina: 0,
      dopunska_prakticna_obuka_cena: 0,
      dopunska_prakticna_obuka_kolicina: 0,
      polaganje_prakticnog_ispita_cena: 0,
      polaganje_prakticnog_ispita_kolicina: 0,
      iznosRate: 0,
      datumRate: '',
      obrasciUgovora: [],
      currentLocale: 'sr',
    }
  },
  computed: {
    ukupnoTeorijskaObuka() { return this.teorijska_obuka_cena * this.teorijska_obuka_kolicina },
    ukupno_dodatna_teorijska_obuka() { return this.dodatna_teorijska_obuka_cena * this.dodatna_teorijska_obuka_kolicina },
    ukupno_dopunska_teorijska_obuka() { return this.dopunska_teorijska_obuka_cena * this.dopunska_teorijska_obuka_kolicina },
    ukupno_polaganje_teorijskog_ispita() { return this.polaganje_teorijskog_ispita_cena * this.polaganje_teorijskog_ispita_kolicina },
    ukupno_prakticna_obuka() { return this.prakticna_obuka_cena * this.prakticna_obuka_kolicina },
    ukupno_dodatna_prakticna_obuka() { return this.dodatna_prakticna_obuka_cena * this.dodatna_prakticna_obuka_kolicina },
    ukupno_dopunska_prakticna_obuka() { return this.dopunska_prakticna_obuka_cena * this.dopunska_prakticna_obuka_kolicina },
    ukupno_polaganje_prakticnog_ispita() { return this.polaganje_prakticnog_ispita_cena * this.polaganje_prakticnog_ispita_kolicina },
    iznos() { return (this.teorijska_obuka_cena * this.teorijska_obuka_kolicina) + (this.dodatna_teorijska_obuka_cena * this.dodatna_teorijska_obuka_kolicina) + (this.dopunska_teorijska_obuka_cena * this.dopunska_teorijska_obuka_kolicina) + (this.polaganje_teorijskog_ispita_cena * this.polaganje_teorijskog_ispita_kolicina) + (this.prakticna_obuka_cena * this.prakticna_obuka_kolicina) + (this.dodatna_prakticna_obuka_cena * this.dodatna_prakticna_obuka_kolicina) + (this.dopunska_prakticna_obuka_cena * this.dopunska_prakticna_obuka_kolicina) + (this.polaganje_prakticnog_ispita_cena * this.polaganje_prakticnog_ispita_kolicina) },
  },
  mounted() {
    this.currentLocale = this.$i18n.locale
    this.$http.get('/autoskole-administratori/cene-obuke').then(response => {
      Object.entries(response.data).forEach(value => {
        this.sabloni.push({ value: value[1].id, text: `${value[1].naziv} ${value[1].kategorija_naziv}` })
      })
    })
    this.$http.get('/autoskole-administratori/ugovor-obrasci-ugovora').then(response => {
      this.obrasciUgovora = response.data
    })
    this.initTrHeight()

    this.$http.get(`/autoskole-administratori/kandidati/${this.$route.params.id}`).then(response => {
      this.kandidat = response.data.kandidat
      this.maloletan = this.kandidat.punoletan === 1 ? { value: 1, text: this.$i18n.t('Maloletan') } : { value: 0, text: this.$i18n.t('Punoletan') }

      this.datum_upisa_u_registar = response.data.kandidat.datum_upisa_u_registar
    })
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    ponistiCene() {
      this.teorijska_obuka_cena = 0
      this.teorijska_obuka_kolicina = 0
      this.dodatna_teorijska_obuka_cena = 0
      this.dodatna_teorijska_obuka_kolicina = 0
      this.dopunska_teorijska_obuka_cena = 0
      this.dopunska_teorijska_obuka_kolicina = 0
      this.polaganje_teorijskog_ispita_cena = 0
      this.polaganje_teorijskog_ispita_kolicina = 0
      this.prakticna_obuka_cena = 0
      this.prakticna_obuka_kolicina = 0
      this.dodatna_prakticna_obuka_cena = 0
      this.dodatna_prakticna_obuka_kolicina = 0
      this.dopunska_prakticna_obuka_cena = 0
      this.dopunska_prakticna_obuka_kolicina = 0
      this.polaganje_prakticnog_ispita_cena = 0
      this.polaganje_prakticnog_ispita_kolicina = 0
    },
    pokupiCene() {
      this.$http.get(`/autoskole-administratori/cene-obuke/${this.sablon.value}`).then(response => {
        this.cene = response.data
        this.teorijska_obuka_cena = this.cene.teorijska_obuka_cena
        this.teorijska_obuka_kolicina = this.cene.teorijska_obuka_kolicina
        this.dodatna_teorijska_obuka_cena = this.cene.dodatna_teorijska_obuka_cena
        this.dodatna_teorijska_obuka_kolicina = this.cene.dodatna_teorijska_obuka_kolicina
        this.dopunska_teorijska_obuka_cena = this.cene.dopunska_teorijska_obuka_cena
        this.dopunska_teorijska_obuka_kolicina = this.cene.dopunska_teorijska_obuka_kolicina
        this.polaganje_teorijskog_ispita_cena = this.cene.polaganje_teorijskog_ispita_cena
        this.polaganje_teorijskog_ispita_kolicina = this.cene.polaganje_teorijskog_ispita_kolicina
        this.prakticna_obuka_cena = this.cene.prakticna_obuka_cena
        this.prakticna_obuka_kolicina = this.cene.prakticna_obuka_kolicina
        this.dodatna_prakticna_obuka_cena = this.cene.dodatna_prakticna_obuka_cena
        this.dodatna_prakticna_obuka_kolicina = this.cene.dodatna_prakticna_obuka_kolicina
        this.dopunska_prakticna_obuka_cena = this.cene.dopunska_prakticna_obuka_cena
        this.dopunska_prakticna_obuka_kolicina = this.cene.dopunska_prakticna_obuka_kolicina
        this.polaganje_prakticnog_ispita_cena = this.cene.polaganje_prakticnog_ispita_cena
        this.polaganje_prakticnog_ispita_kolicina = this.cene.polaganje_prakticnog_ispita_kolicina
      })
    },
    podeliNaRate() {
      const iznosZaPodelu = this.iznos - this.rate[0].iznos
      const iznosRateZaDodavanje = iznosZaPodelu / this.broj_rata_za_podelu

      for (let i = 0; i < this.broj_rata_za_podelu; i += 1) {
        this.rate.push({
          datum: this.addOneMonth(this.rate[i].datum),
          iznos: Math.floor(iznosRateZaDodavanje),
        })

        if (i === this.broj_rata_za_podelu - 1) {
          const sumaRata = this.rate.reduce((a, b) => +a + +b.iznos, 0)
          if (this.iznos !== sumaRata) {
            this.rate[i + 1].iznos += this.iznos - sumaRata
          }
        }

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
        })
      }
    },
    formatIznos(value) {
      const val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    getHumanDate(date) {
      return date !== '' ? moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY.') : ''
    },
    addOneMonth(date) {
      if (date !== '') {
        return moment(date, 'YYYY-MM-DD').add(1, 'months').format('YYYY-MM-DD')
      }

      return moment().add(1, 'months').format('YYYY-MM-DD')
    },
    formSubmitted() {
      const sumaRata = this.rate.reduce((a, b) => +a + +b.iznos, 0)
      if (this.nacin_placanja.value === 'rate' && sumaRata !== this.iznos) {
        this.$bvModal.show('modal-danger')
      } else {
        const ugovorData = {
          cena_teor_obuke: this.teorijska_obuka_cena,
          kol_teor_obuke: this.teorijska_obuka_kolicina,
          cena_dod_teor_obuka: this.dodatna_teorijska_obuka_cena,
          kol_dod_teor_obuka: this.dodatna_teorijska_obuka_kolicina,
          cena_dop_teor_obuka: this.dopunska_teorijska_obuka_cena,
          kol_dop_teor_obuka: this.dopunska_teorijska_obuka_kolicina,
          cena_pol_teor_isp: this.polaganje_teorijskog_ispita_cena,
          kol_pol_teor_ispita: this.polaganje_teorijskog_ispita_kolicina,
          cena_prak_obuke: this.prakticna_obuka_cena,
          kol_prak_obuke: this.prakticna_obuka_kolicina,
          cena_dod_prak_obuke: this.dodatna_prakticna_obuka_cena,
          kol_dod_prak_obuke: this.dodatna_prakticna_obuka_kolicina,
          cena_dop_prak_obuke: this.dopunska_prakticna_obuka_cena,
          kol_dop_prak_obuke: this.dopunska_prakticna_obuka_kolicina,
          cena_pol_prak_isp: this.polaganje_prakticnog_ispita_cena,
          kol_pol_prak_ispita: this.polaganje_prakticnog_ispita_kolicina,
          nacin_placanja: this.nacin_placanja.value,
          rok_realizacije: this.rok_realizacije,
          napomena: this.napomena,
          maloletan: this.maloletan.value,
          roditelj: this.roditelj.value,
          ime_roditelja: this.ime_roditelja,
          broj_lk_roditelja: this.brojLicneKarteRoditelja,
          iznos: this.iznos,
          rateData: this.rate,
          obrazac_ugovora_id: this.obrazac_ugovora_id.id,
        }
        this.$http.post(`/autoskole-administratori/ugovori/${this.$route.params.id}`, { ugovorData }).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('UgovorJeSnimljen'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'autoskola-kandidat-ugovori', params: this.$route.params.id })
        })
      }
    },
    dodajRatu() {
      this.rate.push({
        datum: '',
        iznos: 0,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.rate.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: visible;
  transition: .35s height;
}
</style>
